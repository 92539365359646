import React, { useState } from 'react';
import ".././assets/css/auth.css"
import { toast } from "react-toastify";
import { register,sendOTP,verifyOTP } from '../services/auth.service';
import { useNavigate } from 'react-router-dom';


function Register() {
    const [formData, setFormData] = useState({
      email: '',
      password: '',
      cpassword: '',
    });
    // const [emailErr,setEmailErr] = useState('')
    const [passErr,setpassErr] = useState('')
    const [otperr,setOtpErr] = useState('')
    const [validToggle,setValidToggle] = useState(false)
    const [verifySend,setVerifySend] = useState(false)
    const [otpvalue,setOtpValue] = useState('');
    // const [domainErr,setdomainErr] = useState('')

    const { email, password, cpassword } = formData;



    const navigate = useNavigate();
    
    const checkOTP = async ()=>{
      //TODO:check otp
      
      try {
        let data = {email:email,otp:otpvalue}
        let response = await verifyOTP(data);
              if (response.status) {
                  toast.success(response.msg);
                  setValidToggle(true);
                  setOtpErr(null)
              } else {
                  toast.error(response.msg)
              }
      } catch (error) {
        console.log(error)
      }
    }


    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
const handleVerify =  async (e)=>{
    //outlook
    const validDomains  = [
      'gmail.com',
      'yahoo.com' ,
      'hotmail.com',
      'aol.com',
      'msn.com',
      'live.com',
      'outlook.com',
      'icloud.com',
      'mac.com',
      'me.com',
      'google.com',
      'verizon.net',
      'att.net',
      'rogers.com',
      'sbcglobal.net',
      'bell.net',
      'telus.net',
      'charter.net',
      'shaw.ca',
      'sympatico.ca',
      'live.be',
      'skynet.be',
      'voo.be',
      'telenet.be',
      'orange.fr',
      'wanadoo.fr',
      'free.fr',
      'gmx.de',
      'web.de',
      'yandex.ru',
      'edu.np'
  ]
  const parts = email.split("@");
  let domain = parts[1];
   if(!validDomains.includes(domain)){
    toast.error('This email domain is not supported');
    toast.error('It may not help you recovering data when you needed.');
    return;
   }else{
    toast.success('Email sent to your email address. Please verify your email address.');
    //TODO:Call the email verification function here
    setVerifySend(!verifySend);
    try {
      let response = await sendOTP(email);
            if (response.status) {
                toast.success(response.msg);

            } else {
                toast.error(response.msg)
            }
    } catch (error) {
      console.log(error)
    }
    
   }
   


  }
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      // Perform form validation here
        if(password.length <6){
            setpassErr('Minimum password length is 6')
            return;
        }
      if (password !== cpassword) {
        setpassErr('Password does not match')
        return;
      }
     
        try {

            let response = await register(formData);
            if (response.status) {
                toast.success(response.msg);
                navigate('/login')

            } else {
                toast.error(response.msg)
            }
        } catch (error) {
            console.log(error)
        }
    
    };
  
  return (
    <div>
    <div className="login-component " style={{height:'440px'}}>
      <h1 className="login-title">Register</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={email}
          onChange={handleChange}
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={password}
          onChange={handleChange}
        />
        <input
          type="password"
          name="cpassword"
          placeholder="Confirm Password"
          value={cpassword}
          onChange={handleChange}
        />
        <em className='text-danger'>{passErr}</em>
        {!verifySend ? <button  className='auth-button' type='button' onClick={handleVerify}>Send Verification Email</button> :
          <>
                <input
                type="text"
                style={{
                  fontSize: '16px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                }}
              placeholder="Enter OTP"
              value={otpvalue}
              onChange={(e)=>setOtpValue(e.target.value)}
            />
            {otperr && <em className='text-danger'>{otperr}</em>}
            {!validToggle && <button className='auth-button' type='button' onClick={checkOTP}>Verify</button>}
          </>
        }
        {validToggle && <button className='auth-button' type="submit">Register</button>}
      </form>
    </div>
  </div>
  );
}

export default Register;
