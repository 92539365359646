import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom"
import './assets/css/nav.style.css'; // Import your CSS file
import { Welcome, Navbar, About } from './components/base.component';
// import { Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './auth/login.page';
import Register from './auth/register.page';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"




function App() {
  const [isSticky, setSticky] = useState(false);
  const stickyRef = useRef(null);
  localStorage.setItem('viewList', true)


  useEffect(() => {
    const handleScroll = () => {
      window.pageYOffset > stickyRef.current.getBoundingClientRect().bottom
        ? setSticky(true)
        : setSticky(false);
    };

    const debounce = (func, wait = 20, immediate = true) => {
      let timeOut;
      return () => {
        const context = this;
        const args = arguments;
        const later = () => {
          timeOut = null;
          if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeOut;
        clearTimeout(timeOut);
        timeOut = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    };

    const scrollListener = debounce(handleScroll);
    window.addEventListener("scroll", scrollListener);

    return () => {
      window.removeEventListener("scroll", scrollListener);
    };
  }, []);

  return (
    <React.Fragment>
      <BrowserRouter>
        <Navbar sticky={isSticky} />
        <ToastContainer
          autoClose={1400}
          style={{ width: '300px',  textAlign:'center'}} // Set your preferred width and height
        />
        <Routes>
          <Route path='/' element={<Welcome stickyRef={stickyRef} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/about" element={<About/>}/>
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;