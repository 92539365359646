import React, { useState } from 'react';
import { toast } from "react-toastify";
import { login } from '../services/auth.service';
import { NavLink, useNavigate } from 'react-router-dom';

import ".././assets/css/auth.css";

function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [emailErr, setEmailErr] = useState('');
  const [passErr, setPassErr] = useState('');

  const { email, password } = formData;


  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform form validation here
    if (!email || !email.includes('@')) {
      setEmailErr('Invalid email format');
      return;
    }
    if (password.length < 6) {
      setPassErr('Minimum password length is 6');
      return;
    }

    try {
      const response = await login(formData);
      console.log("response",response)
      if (response.status) {
        
        toast.success(response.msg);
        navigate('/');
        // Redirect to the desired page on successful login
      } else {
        toast.error(response);
      }
    } catch (error) {
      console.error(error);
      // Handle error, e.g., show an error toast
    }
  };

  return (
    <div>
      <div className="login-component">
        <h1 className="login-title">Login</h1>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={email}
            onChange={handleChange}
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={handleChange}
          />
          <em className='text-danger'>{emailErr}</em>
          <em className='text-danger'>{passErr}</em>
          <button className='auth-button' type="submit">Login</button>
        </form>
        <em className='text-white'>Don't have an Account? <NavLink to='/register' className='text-success'>Sign Up Here</NavLink></em>
      </div>
    </div>
  );
}

export default Login;
