import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPenToSquare, faPlus, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import "../assets/css/pinbar.style.css";
import { Row, Col } from 'react-bootstrap';
// import { useSelector } from 'react-redux';
import { getUserMails, updateMail, deleteMail } from '../services/mail.service';
import { toast } from 'react-toastify';

const PinBar = ({ onPin }) => {
  const [emails, setEmails] = useState([]);
  const [activeButtonIndex, setActiveButtonIndex] = useState(null);
  const [editableEmailIndex, setEditableEmailIndex] = useState(null);
  const [deleteableEmailIndex, setDeleteEmailIndex] = useState(null);
  const [open, setOpen] = useState(false);
  const [desc, setDesc] = useState(""); // Changed "setdesc" to "setDesc"
  const [load, setLoad] = useState(false); // Initialize "load" with a value

  const getAllEmails = async () => {
    let response = await getUserMails();
    if (response.status) {
      setEmails(response.result);
    } else {
      console.log(response);
    }
  }

  useEffect(() => {
    getAllEmails();
  }, [onPin, open, load]); // Removed the empty dependency array

  const handleEmailView = (index) => {
    setActiveButtonIndex(index);
    localStorage.setItem("mail_id", emails[index]?.mail_id);
  }

  const handleEmailEdit = (index) => {
    setOpen(!open);
    setEditableEmailIndex(index);
  }

  const handleEmailDelete = async (item, index) => {
    setDeleteEmailIndex(index);

    try {
      let address = item.mail_id;
      let response = await deleteMail(address);
      if (response.status) {
        toast.success(response.msg);
        setLoad(!load);
      } else {
        toast.error('An Error Occurred while Deleting');
        setLoad(!load);
      }
    } catch (error) {
      console.log(error);
      console.log(deleteableEmailIndex)
    }
  }

  const handleSubmitPin = async (item) => {
    let obj = {};
    try {
      let address = item.mail_id;
      obj.description = desc;
      let response = await updateMail(obj, address);
      if (response.status) {
        toast.success(response.msg);
        setOpen(false);
      } else {
        toast.error('An Error Occurred while Updating');
        setOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <h3 className='text-white'>Pinned Email</h3>
      <div className='scroll'>
        {emails.length > 0 ? emails.map((item, index) => (
          <div key={index}>
            <Row style={{ color: '#c0c0c0', alignItems: 'center', justifyContent: 'space-between' }}>
              <Col sm={1}>
                <span>{index + 1}</span>
              </Col>
              <Col sm={2}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <button
                    onClick={() => handleEmailView(index)}
                    style={{
                      width: '50px',
                      height: '50px',
                      borderRadius: '50%',
                      border: '2px solid white',
                      backgroundColor: activeButtonIndex === index ? 'rgb(49,169,110)' : 'rgb(39, 39, 39)',
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} size="xl" style={{ color: 'white' }} />
                  </button>
                </div>
              </Col>
              <Col sm={3}>{item.mail_id}</Col>
              <Col sm={2}></Col>
              <Col sm={4}>
                <Row>
                  <Col>
                    <div style={{ cursor: 'pointer' }} className='editbtn' onClick={() => handleEmailEdit(index)}>
                      <FontAwesomeIcon icon={faPenToSquare} size="xl" style={{ color: '#5085e2' }} />
                    </div>
                  </Col>
                  <Col>
                    <div onClick={() => handleEmailDelete(item, index)} style={{ cursor: 'pointer' }}>
                      <FontAwesomeIcon icon={faTrash} size="xl" style={{ color: '#d74b28' }} />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ color: 'white', borderBottom: '1px solid white' }}>
              {(editableEmailIndex === index) && open ? (
                <div className='row'>
                  <Col sm={9}>
                    <input placeholder='Update description' type="text" value={desc}
                      onChange={(e) => setDesc(e.target.value)} />
                  </Col>
                  <Col sm={3}>
                    <button className='btn btn-success'><span className='mr-2' onClick={() => handleSubmitPin(item)}>Submit</span>
                      <FontAwesomeIcon icon={faPaperPlane} style={{ color: 'white' }} />
                    </button>
                  </Col>
                </div>
              ) : (
                <small className="text-center" style={{ backgroundColor: 'rgb(70, 70, 85)' }}>
                  {item.description}
                </small>
              )}
            </Row>
          </div>
        )) : <></>}
      </div>
    </>
  )
}

export default PinBar;
