import { getRequest, postRequest } from "./axios.service"
// import { toast } from "react-toastify";

const APT_ENDPOINTS = {
    LOGIN_URL: "/login",
    REGISTER_URL:"/register",
    VERIFY_USER: "/login-verify",
    GET_OTP:"/otp-code",
    VERIFY_OTP:"/otp-verify"
}


export const login = async (data) => {
    
    try {
        let login_response = await postRequest(APT_ENDPOINTS.LOGIN_URL,data);
        if(login_response.result.access_token){
            localStorage.setItem("auth_token",login_response.result.access_token)
            let name = login_response.result.user.email.split("@")
            name = name[0];
            let user_info = {
                email: login_response.result.user.email,
                _id: login_response.result.user._id,
                name:name
               
            }
            localStorage.setItem("auth_user",JSON.stringify(user_info));
            
            return login_response;
        
        }   
        else{
            // console.log("loginresp",login_response.msg + "haha")
            return login_response.result;
        }
     } catch (error) {
        // console.log("loginresp",error)
         return error.response.data.msg
     }
}

export const register = async (data) =>{
    try{
        delete data.cpassword;
        console.log("data-reg",data)
        

        let response = await postRequest(APT_ENDPOINTS.REGISTER_URL,data,false,false);
        return response;
    }catch(error){
        return error.response.data.msg;
    }
}
export const sendOTP = async (email) =>{
    try{
        let response = await postRequest(APT_ENDPOINTS.GET_OTP,{email:email},false,false);
        return response;
    }catch(error){
        return error.response.data.msg;
    }
}
//Verify OTP Code
export const verifyOTP = async (data) =>{
    try{
        
        let response = await postRequest(APT_ENDPOINTS.VERIFY_OTP,{email:data.email,otp:data.otp},false,false);
        return response;
    }catch(error){
        return error.response.data.msg;
    }
}
export const getVerified = async () =>{
    try{
        let response  = await getRequest(APT_ENDPOINTS.VERIFY_USER,true)
        // debugger;
        // console.log(response)
       return response;
    }catch(error){
        //TODO: HANDLE ERROR  
        throw error     
    }
}
