import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import DataTable from 'react-data-table-component';
import { FaArrowRight } from "react-icons/fa6";

import React, { useState, useEffect } from 'react';
import '../assets/css/email.css';
import { Col, Row } from 'react-bootstrap';
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { BsPinAngleFill } from 'react-icons/bs';
import { FiRefreshCcw } from 'react-icons/fi';

// import Listing from './listing.component';
import ViewComponent from './view.component';
import { getId, getMail, getMsg } from '../services/message.service';
// import { useDispatch } from 'react-redux';
// import { pinEmails } from '../redux/pinaction';
import { addEmail } from '../services/mail.service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


function MessageBox({ onClose, selectedRowData }) {

    //selectedRowData.id

    const [message, setMessage] = useState('');

    let id = selectedRowData.id;
    const getMessage = async () => {
        try {
            const response = await getMsg(id);
            if (response) {
                console.log("msg resp", response)
                setMessage(response); // update the state variable with the response data
            }
        } catch (err) {
            console.log(err);
        }
    };



    useEffect(() => {
        getMessage()
        // eslint-disable-next-line
    },[]);

    return (
        <>
            {
                message && (<div className="box">
                    {/* <button className='iconbtn' onClick={onClose}>back</button> */}
                    <div>
                        <ViewComponent message={message} onClose={onClose} />
                    </div>
                </div>)
            }
        </>
    );
}



function Inbox() {
    const [showMessageBox, setShowMessageBox] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);

    const mail = localStorage.getItem('mail_id')
    console.log("mail",mail)

    function handleViewMessageClick(rowData) {
        setSelectedRowData(rowData);
        setShowMessageBox(true);
    }

    const columns = [
        {
            name: '',
            selector: row => row.from,
        },
        {
            name: '',
            selector: row => row.subject,
        },
        {
            name: '',
            selector: row => row.id,
            cell: row => <div style={{
                cursor: 'pointer',
              }} onClick={() => handleViewMessageClick(row)} ><FaArrowRight fontSize="22"/>
            </div>
          ,
        },
    ];



    function handleCloseMessageBox() {
        setShowMessageBox(false);
    }

    const [mailList, setMailList] = useState([]);

    const getEmailList = async () => {
        try {
            const response = await getMail();
            if (response) {

                setMailList(response); // update the state variable with the response data
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            getEmailList();
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            {showMessageBox ? (
                <MessageBox onClose={handleCloseMessageBox} selectedRowData={selectedRowData} />
            ) : (
                <Row style={{ borderRadius: '10px', marginTop: '5%', marginRight: '1%', marginLeft: '0 !important' }}>
                    <Row className='text-center'> <sm>{mail}</sm></Row>
                    <Row style={{ backgroundColor: 'rgb(25, 27, 32)', padding: '15px', fontWeight: 'bold', fontSize: '1.2rem' }}>
                    
                        <Col>Sender</Col>
                        <Col>Subject</Col>
                        <Col>View</Col>
                       
                        
                    </Row>

                    <div style={{ backgroundColor: 'white', color: 'black', height: '40vh', marginRight: '1%', marginLeft: 'inherit' }} className='inbox-scroll'>
                        {
                            mailList.length > 0 ? <DataTable columns={columns} data={mailList} noTableHead /> : (<div style={{ marginRight: '14%' }}>
                                <p ><FontAwesomeIcon icon={faEnvelope} spin style={{ color: "#c73333", fontSize: '5rem', marginTop: '10%' }} /></p>
                                <p style={{ fontSize: '1.2rem' }}>Your Inbox is empty</p>
                                <p style={{ fontSize: '1rem' }}>Waiting for incoming emails</p>
                            </div>)

                        }


                    </div>
                </Row>
            )}
        </>
    );
}


const Generate = ({onPin}) => {
    const [data, setData] = useState("Loading ...");
    // const [countdown, setCountdown] = useState(600);
    const [copied, setCopied] = useState(false);
    // const [openmsg, setOpenMsg] = useState(false)
    // const [currentMsg, setCurrentMsg] = useState();
    const [refresh, setRefresh] = useState(false)
    const [pinValue, setPinValue] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    // const dispatch = useDispatch();
    const navigate = useNavigate();
    const copyToClipboard = () => {
        const input = document.getElementById('myInput');
        navigator.clipboard.writeText(input.value);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    useEffect(() => {
        const getEmailId = async () => {
            try {
                let response = await getId();
                console.log('resp:', response);
                if (response.length > 0) {
                    setData(response[0]);
                } else {
                    console.log(response);
                }
            } catch (err) {
                console.log(err);
            }
        };

        getEmailId();

    }, [refresh]);

    //
    const newPin = async () => {
        let localUser = JSON.parse(localStorage.getItem('auth_user'))
        if(localUser){

            setIsModalOpen(!isModalOpen);
        }else{
            navigate('/login')
        }

       
    }

    const handleSubmitPin = async() => {
        setPinValue(pinValue);
        let obj = {};
        obj.mail_id = data;
        const parts = data.split("@");
        obj.username = parts[0];
        obj.domain = parts[1];
        obj.description = pinValue

        // submit call to server
       try {
            let response =  obj.description && await addEmail(obj)
            if(!response.status){
                toast.error('Error Occured while Adding Email to Pin')
            }else{
                console.log("pinnded",obj)
                onPin()
            }
        } catch (error) {
            console.log(error)
        }
        setIsModalOpen(false);
    };





    return (
        <>
            <div className="emailbox">
                <div className="row" >
                    <Col sm={1}></Col>
                    <Col sm={7} >
                        <h3 className='text-center mb-3' > Your Temporary Email Address</h3>
                        {data ? (
                            <input type="text" id="myInput" style={{ fontWeight: 'bold', fontSize: '1.2rem' }} readOnly value={data} />
                        ) : (
                            <input type="text" id="myInput" style={{ fontWeight: 'bold', fontSize: '1.2rem' }} readOnly value="Loading ..." />
                        )}
                    </Col>
                    <Col sm={4}>
                        <button className="back mt-5" onClick={copyToClipboard}>
                            <FontAwesomeIcon icon={faCopy} />
                        </button>
                        <button className="addbtn mt-5" onClick={newPin}>
                            <BsPinAngleFill />
                        </button>
                        <button className="back mt-5" onClick={() => setRefresh(!refresh)}>
                            <FiRefreshCcw />
                        </button>
                    </Col>
                </div>
                {copied && <em className="emtag">Copied to clipboard</em>}
                
                <Row>
                  <Col sm={9}>
                  {!isModalOpen &&
                    <sm style={{
                        color: 'gray',
                        padding: '5% 15% 2% 8%'
                    }}>
                        <p>Forget about spam, advertising mailings, hacking and attacking robots. Keep your real mailbox clean and secure. Temp Mail provides temporary, secure, anonymous, free, disposable email address.</p>
                       </sm>
                    }
                    {
                    isModalOpen && <div>
                    <h5>Enter Description</h5>
                    <input
                        type="text"
                        placeholder="Enter Description: eg: facebook username: pubg"
                        value={pinValue}
                        onChange={(e) => setPinValue(e.target.value)}
                    />
                    <button onClick={handleSubmitPin} style={{width:'100%',padding:'8px',borderRadius:'10px'}}>Pin Email</button>
                </div>
            }
   
                  </Col>
                </Row>
            </div>
            
            <div className='messagebox text-white'>
                {/* <Row style={{ borderRadius: '10px', marginTop: '5%', marginRight: '1%', marginLeft: '0 !important' }}>
                    <Row style={{ backgroundColor: 'rgb(25, 27, 32)', padding: '15px', fontWeight: 'bold', fontSize: '1.2rem' }}>
                        <Col>Sender</Col>
                        <Col>Subject</Col>
                        <Col>View</Col>
                    </Row>

                    <div style={{ backgroundColor: 'white', color: 'black', height: '40vh' }} className='inbox-scroll'>
                        
                        
                          

                        
                    </div>
                </Row> */}
                <Inbox />






            </div>
        </>
    );
}

export default Generate;
