import React, { useRef } from 'react';
import { Row,Col } from 'react-bootstrap';
const ViewComponent = ({ message ,onClose}) => {
  const messageRef = useRef(null);

  const handleLinkClick = (e) => {
    e.preventDefault();
    const url = e.target.href;
    window.open(url, '_blank');
  };

  // const mail = JSON.parse(localStorage.getItem('mail_id')) ?? null;

  const handleContainerClick = (e) => {
    const { tagName } = e.target;
    if (tagName === 'A') {
      handleLinkClick(e);
    }
  };

  return (<>
  <Row style={{ borderRadius: '10px', marginTop: '5%', marginRight: '1%', marginLeft: '0 !important' }} ref={messageRef} onClick={handleContainerClick}>
                    <Row style={{ backgroundColor: 'rgb(25, 27, 32)', padding: '15px', fontSize: 'large' }}>
                        <Col onClick={onClose} style={{cursor:'pointer'}}>Back To List</Col>
                        {/* <Col>{mail}</Col> */}
                        <Col style={{cursor:'pointer'}}>Delete</Col>
                    </Row>

                    <div style={{ backgroundColor: 'white', color: 'black', height: '40vh',marginRight:'1%' , marginLeft: 'inherit'}} className='inbox-scroll'>
                              <Row style={{borderBottom:'1px solid gray'}}>
                                <Col sm={8}> <h6 className='text-start'>{message.from}</h6></Col>
                                <Col sm={4}> <h6>{message.date}</h6></Col>
                              </Row>
                              <Row style={{borderBottom:'1px solid gray'}}>
                                <h6> Subject: {message.subject}</h6>  
                              </Row>
                              <div dangerouslySetInnerHTML={{ __html: message.htmlBody }} />
                              {
                                message.attachments && message.attachments.map((item,index)=>
                                  <Col key={index} sm={3}>
                                  <img src='' alt={item.filename}></img>
                                    <small className="text-center">{item.filename}</small> 
                                    <button>Download</button>
                                                          
                                </Col>
                                )
                              }

                              
                    </div>
    </Row>
  </>
    // <div className='msgbg' ref={messageRef} onClick={handleContainerClick}>
    //   <h4>From: {message.from}</h4>
    //   <h4>Subject: {message.subject}</h4>
    //   <h4>Date: {message.date}</h4>
    //   <div dangerouslySetInnerHTML={{ __html: message.htmlBody }} />
     // </div>

  );
}

export default ViewComponent;
