import { deleteRequest, getRequest, postRequest, putRequest } from "./axios.service"
// import { toast } from "react-toastify";

const APT_ENDPOINTS = {
    ADD_MAIL: "/email/addemail",
    FETCH_MAILS:"/email/getallemail",
    UPDATE_MAIL:"/update-email"
}




export const addEmail = async (data) =>{
    try{
        let response = await postRequest(APT_ENDPOINTS.ADD_MAIL,data,true,false);
        return response;
    }catch(error){
        return error.response.data.msg;
    }
}
export const getUserMails  = async ()=>{
    try {
        let response = await getRequest(APT_ENDPOINTS.FETCH_MAILS,true);
        return response;
    } catch (error) {
        throw error
    }
}
//router.put('/update-email/:address',loginCheck,eml_ctrl.updateEmail)

export const updateMail = async (data,address)=>{
    try{
        let response = await putRequest(`/email/update-email/${address}`, data, true, false);
        return response;
    }catch(err){
        throw err
    }
}
export const deleteMail = async (address)=>{
    try{
        let response = await deleteRequest(`/email/dltemail/${address}`, true, false);
        return response;
    }catch(err){
        throw err
    }
}