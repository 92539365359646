import { ActionTypes } from "./actionType";

const initialState = {
    emails:[]
}

export const emailReducer = (state = initialState, {type,payload})=>{
    switch (type) {
        case ActionTypes.SET_EMAILS:
            return {...state,emails:payload};
           
    
        default:
            return state;
    }
}