import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope,faUser,faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import Generate from './generate.component';
import {Row,Col } from 'react-bootstrap'
import PinBar from './pinbar.component';
import {NavLink, useNavigate} from "react-router-dom"
// import { login } from '../services/auth.service';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';




const rowStyle = {
  // paddingTop: '3%',
  marginLeft: '1%',
  marginRight: '1%',
  // border: '2px solid green',
};
export const Welcome = ({ stickyRef }) => {
  const[onPin,setOnPin] = useState(false)

  function handlePin() {
    setOnPin(!onPin);
}
useEffect(()=>{

},[])

  return(
  <main>
    <section className="welcome">
      <div ref={stickyRef}>
        <Row style={rowStyle}>
          <Col sm={7}>
            <Generate onPin={handlePin}/>
          </Col>
          <Col sm={5} >
            <PinBar onPin={onPin} />
          </Col>
        </Row>
      </div>
    </section>
  </main>)
}














const navStyle = {
  color:'white',
  textDecoration:'none',
}

// const localUser = JSON.parse(localStorage.getItem('auth_user')) ?? null;

  export const Navbar = ({ sticky }) => {
    const localUser = JSON.parse(localStorage.getItem('auth_user')) ?? null;
    const navigate = useNavigate();
    const [event, setEvent] = useState(false);
  
    const handleLogout = () => {
      localStorage.removeItem('auth_user');
      localStorage.removeItem('access_token');
      toast.info("You're logged Out! Please login again");
      setEvent(!event); // Toggle the state to force a re-render
      navigate('/login');
    };
  
    useEffect(() => {
      // Handle any additional logic after a re-render
    }, [event]);
    return (
    <nav className={sticky ? "navbar navbar-sticky" : "navbar"}>
      <NavLink to={'/'} className="navbar--logo-holder" style={navStyle}>
        {sticky ?<i className="fas fa-envelope" style={{color:'#df410c'}} ></i> : null}
        <span style={{fontSize:'1.6rem',fontWeight:'bold'}}>
          <FontAwesomeIcon className='navbar--logo' icon={faEnvelope}  size='xl' style={{color:'#df322f',marginRight:'10px'}}  />
         Secure'Inbox</span>

      </NavLink>
      <ul className="navbar--link">
        
        <NavLink className="navbar--link-item" style={navStyle} to="/">Home</NavLink>
        <NavLink style={navStyle} className="navbar--link-item" to="/about">About</NavLink>
        {
          !localUser &&  <NavLink to={'/login'} className="navbar--link-item" style={navStyle} >Login</NavLink>

        }{
          localUser && <li className="navbar--link-item" style={navStyle}><FontAwesomeIcon icon={faUser} style={{color: "#f2f2f2",marginRight:'6px'}} />{localUser.name}</li>
        }
         {localUser && (
          <li className="navbar--link-item" style={navStyle} onClick={handleLogout}>
            <FontAwesomeIcon icon={faRightFromBracket} style={{ color: '#ffffff' }} />
          </li>
        )}
      </ul>
    </nav>)
  };

  

export const About = () => {
  const styles = {
    aboutPage: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '20px',
    },
    aboutHeader: {
      textAlign: 'center',
      // backgroundColor: '#f2f2f2',
      padding: '20px',
    },
    aboutContent: {
      margin: '20px 0',
    },
    aboutTeam: {
      margin: '20px 0',
    },
    footer: {
      backgroundColor: '#333',
      color: '#fff',
      padding: '10px',
      textAlign: 'center',
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
    }
  };

  return (
    <div style={styles.aboutPage} className='text-white'>
      <div style={styles.aboutHeader}>
        <h1>About Our Service</h1>
        {/* <h3 className='text-center'>Your Trusted Temporary Email Solution</h3> */}
      </div>
      <div style={styles.aboutContent}>
        <p>
          We understand the importance of online privacy and security. Our
          service was created to help you protect your primary email address
          while enjoying the convenience of disposable email addresses.
        </p>
        <p>
          With our unique Pin feature, you can easily manage and organize your
          temporary email addresses. Pin your most important emails and add
          descriptions to keep track of them effortlessly.
        </p>
        <p>
          We are committed to providing you with a secure and user-friendly
          experience. Your privacy is our priority, and we are constantly
          working to enhance our services to meet your needs.
        </p>
      </div>
      <footer style={styles.footer}>
        <Row>
          <Col sm={3}>

          </Col>
          <Col sm={4}>
          &copy; 2023 secureinbox.me All rights reserved.
          </Col>
          <Col sm={3}>
            <a href="https://linkedin.com/in/imsagar99/">Developer: Sagar Poudel </a>

          </Col>
        </Row>
       
      </footer>

    </div>
  );
};
